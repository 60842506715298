define(['app', 'siteObj', '$console'], function(app, siteObj, $console) {
  'use strict';
  var enhancedEcommerce = {
    enEcommerceValues: function(clickElement, multiple) {

      var qvPriceElement = app.element.closest('.js-product-price', clickElement).element;
      var qvPrice = qvPriceElement.innerText !== undefined ? qvPriceElement.innerText.replace(/[^\d.,]/g, '') : '';

      var priceElement = app.element.closest('span[data-product-price]', clickElement).element;
      var dataPrice = app.element.getAttribute('data-product-price', priceElement).replace(/[^\d.,]/g, '');

      var price = qvPrice || dataPrice;


      //if no quantity picker is available quantity is 1
      var quantityElement = app.element.closest('input.qty-picker', clickElement).element;
      var quantity = quantityElement.value !== undefined ? quantityElement.value : 1;

      //basket page multiple quantity i.e. basket row is deleted
      if (multiple === true) {
        quantityElement = app.element.closest('span[data-product-quantity]', clickElement).element;
        quantity = parseInt(app.element.getAttribute('data-product-quantity', quantityElement));
      }

      var productTitleElement = app.element.closest('span[data-product-title]', clickElement).element;
      var productTitle = app.element.getAttribute('data-product-title', productTitleElement).replace(/(\r\n|\n|\r)/gm, '');
      var productBrand = app.element.getAttribute('data-product-brand', productTitleElement);

      //get master SKU
      var masterProductSkuElement = app.element.closest('span[data-product-master-product-id]', clickElement).element;
      var masterSku = app.element.getAttribute('data-product-master-product-id', masterProductSkuElement);


      //variation is populated after ajax call - sometimes ie when firs landing on a product page,  we need to get the details off the page itself
      var variationSkuFromUrl;
      if (siteObj.type === 'product' && !siteObj.productVariationID) {
        var windowUrl = window.location.href;
        var variationPosition = windowUrl.indexOf('variation=');
        var windowUrlArr = [];
        var temp = null;
        variationSkuFromUrl = '';

        if (variationPosition > -1) {
          //grab variation only if variation= is in the url
          windowUrlArr = windowUrl.split('?');

          windowUrlArr.map(function(string) {
            $console.log(string);
            if (string.indexOf('variation=') > -1) {
              temp = string.split('=');
              variationSkuFromUrl = temp[1];
            }
          });
        }
      }

      //SKU of element
      //variantSku is available from product pages when a variation is chosen
      var variantSku = siteObj.productVariationID || variationSkuFromUrl || null;
      var productSkuElement = app.element.closest('span[data-product-id]', clickElement).element;
      var productSku = app.element.getAttribute('data-product-id', productSkuElement);

      //set id as master SKU if one is avaiable otherwise use the product SKU
      var id = '';

      //some products with a master sku only give us a true or false as to whether one exists or not.
      //this is temporary because of our odd data when the handlebars templates are deleted we can tidy the logic
      if (!masterSku || masterSku === true) {
        masterSku = false;
      }
      id = masterSku || productSku;

      var productPositionElement = app.element.closestI('span[data-product-position]', clickElement).element;
      var productPosition = parseInt(app.element.getAttribute('data-product-position', productPositionElement));

      return [{
        'name': productTitle,
        'id': id,
        'masterId': masterSku || '',
        'variationSku': variantSku || productSku || '',
        'price': price,
        'brand': productBrand || ' ',
        'category': ' ',
        'variant': siteObj.productTitle || ' ',
        'quantity': quantity,
        'coupon': ' ',
        'list': siteObj.sectionName || siteObj.page || siteObj.type,
        'position': productPosition || 0,
        'pageType': siteObj.page || siteObj.type
      }];

    },

    sendValues: function(pageEvent, classToCheck, ggEvent, ggAction, multiple) {
      var clickElement = pageEvent.target;
      var clickedClass = app.element.getAttribute('class', clickElement) || '';

      if (clickedClass.indexOf(classToCheck) > -1) {
        var productsArray = enhancedEcommerce.enEcommerceValues(clickElement, multiple || false);
        app.publish('tracking/ecommerce', ggEvent, ggAction, 'products', productsArray, siteObj.currencyType);
      }
    },

    productPageVariations: function(result) {
      //add variation details to site object
      enhancedEcommerce.clearSiteObjValues();
      siteObj.productVariationID = result.productId.toString();
      siteObj.productTitle = result.title;
    },

    clearSiteObjValues: function() {
      siteObj.productVariationID = null;
      siteObj.productTitle = null;
    }

  };
  return enhancedEcommerce;
});
